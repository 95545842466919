@font-face {
  font-family: "reside";
  src: url("../font/reside.eot?45082694");
  src: url("../font/reside.eot?45082694#iefix") format("embedded-opentype"),
    url("../font/reside.woff2?45082694") format("woff2"),
    url("../font/reside.woff?45082694") format("woff"),
    url("../font/reside.ttf?45082694") format("truetype"),
    url("../font/reside.svg?45082694#reside") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'reside';
    src: url('../font/reside.svg?45082694#reside') format('svg');
  }
}
*/

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "reside";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-alert:before {
  content: "\e800";
} /* '' */
.icon-reports:before {
  content: "\e801";
} /* '' */
.icon-permissions-share-access:before {
  content: "\e802";
} /* '' */
.icon-clock:before {
  content: "\e803";
} /* '' */
.icon-permissions:before {
  content: "\e804";
} /* '' */
.icon-dots:before {
  content: "\e805";
} /* '' */
.icon-down:before {
  content: "\e806";
} /* '' */
.icon-download:before {
  content: "\e807";
} /* '' */
.icon-drag:before {
  content: "\e808";
} /* '' */
.icon-duplicate:before {
  content: "\e809";
} /* '' */
.icon-editor-bold:before {
  content: "\e80a";
} /* '' */
.icon-editor-email:before {
  content: "\e80b";
} /* '' */
.icon-editor-headline-main:before {
  content: "\e80c";
} /* '' */
.icon-editor-headline-secondary:before {
  content: "\e80d";
} /* '' */
.icon-editor-image:before {
  content: "\e80e";
} /* '' */
.icon-editor-new-question:before {
  content: "\e80f";
} /* '' */
.icon-editor-link:before {
  content: "\e810";
} /* '' */
.icon-editor-new-slide:before {
  content: "\e811";
} /* '' */
.icon-editor-new-text:before {
  content: "\e812";
} /* '' */
.icon-editor-ordered-list:before {
  content: "\e813";
} /* '' */
.icon-editor-small-text:before {
  content: "\e814";
} /* '' */
.icon-editor-unordered-list:before {
  content: "\e815";
} /* '' */
.icon-editor-video:before {
  content: "\e816";
} /* '' */
.icon-email:before {
  content: "\e817";
} /* '' */
.icon-compare:before {
  content: "\e818";
} /* '' */
.icon-analytics:before {
  content: "\e819";
} /* '' */
.icon-export:before {
  content: "\e81a";
} /* '' */
.icon-left:before {
  content: "\e81b";
} /* '' */
.icon-message:before {
  content: "\e81c";
} /* '' */
.icon-message-alt:before {
  content: "\e81d";
} /* '' */
.icon-notification:before {
  content: "\e81e";
} /* '' */
.icon-options-checkboxes:before {
  content: "\e81f";
} /* '' */
.icon-options-date:before {
  content: "\e820";
} /* '' */
.icon-options-file-upload:before {
  content: "\e821";
} /* '' */
.icon-options-dropdowns:before {
  content: "\e822";
} /* '' */
.icon-options-multiple-choice:before {
  content: "\e823";
} /* '' */
.icon-options-paragraph:before {
  content: "\e824";
} /* '' */
.icon-options-short-answer:before {
  content: "\e825";
} /* '' */
.icon-options-time:before {
  content: "\e826";
} /* '' */
.icon-pencil:before {
  content: "\e827";
} /* '' */
.icon-phone:before {
  content: "\e828";
} /* '' */
.icon-right:before {
  content: "\e829";
} /* '' */
.icon-search:before {
  content: "\e82a";
} /* '' */
.icon-sort-down:before {
  content: "\e82b";
} /* '' */
.icon-sort-up:before {
  content: "\e82c";
} /* '' */
.icon-trash:before {
  content: "\e82d";
} /* '' */
.icon-up:before {
  content: "\e82e";
} /* '' */
.icon-user:before {
  content: "\e82f";
} /* '' */
.icon-check-filled:before {
  content: "\e830";
} /* '' */
.icon-a-letter:before {
  content: "\e831";
} /* '' */
.icon-index:before {
  content: "\e832";
} /* '' */
.icon-overview:before {
  content: "\e833";
} /* '' */
.icon-camera:before {
  content: "\e834";
} /* '' */
.icon-trash-2:before {
  content: "\e835";
} /* '' */
.icon-arrow-up:before {
  content: "\e836";
} /* '' */
.icon-calendar:before {
  content: "\e837";
} /* '' */
.icon-check-circle:before {
  content: "\e838";
} /* '' */
.icon-add:before {
  content: "\e839";
} /* '' */
.icon-clear:before {
  content: "\e83a";
} /* '' */
.icon-info:before {
  content: "\e83b";
} /* '' */
.icon-lock:before {
  content: "\e83c";
} /* '' */
.icon-text-size:before {
  content: "\e83d";
} /* '' */
.icon-pause:before {
  content: "\e83e";
} /* '' */
.icon-flag-outlined:before {
  content: "\e83f";
} /* '' */
.icon-flag-alt:before {
  content: "\e840";
} /* '' */
.icon-flag:before {
  content: "\e841";
} /* '' */
.icon-house:before {
  content: "\e842";
} /* '' */
.icon-back:before {
  content: "\e843";
} /* '' */
.icon-close:before {
  content: "\e844";
} /* '' */
.icon-check:before {
  content: "\e845";
} /* '' */
.icon-audio:before {
  content: "\e846";
} /* '' */
.icon-arrows-cw:before {
  content: "\e847";
} /* '' */
.icon-cloud:before {
  content: "\e848";
} /* '' */
.icon-print:before {
  content: "\e849";
} /* '' */
.icon-grid:before {
  content: "\e84a";
} /* '' */
.icon-arrows:before {
  content: "\e84d";
} /* '' */
.icon-download-cloud:before {
  content: "\f0ed";
} /* '' */
.icon-upload-cloud:before {
  content: "\f0ee";
} /* '' */
.icon-file-pdf:before {
  content: "\f1c1";
} /* '' */
