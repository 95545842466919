body {
  color: #203c63;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: normal;
}

#root {
  height: 100%;
  overflow: hidden;
}

div:focus {
  outline: none;
}
